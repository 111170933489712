<template>
    <div class="Pb">
        <el-form ref="formRef" :model="form" :rules="rules" label-width="100px">
            <el-form-item label="司机" prop="driverId">
                <el-select :ref="el=>refMap.set('driverId',el)" v-model="form.driverId" clearable placeholder="请选择司机" style="width: 100%" :disabled="disabled" @change="(val)=>{selectOnChange(val,'driverId')}">
                    <el-option v-for="item in comboSelect({comboId:'driverId'})" :key="item.value" :label="item.label" :value="item.value" :disabled="item.disabled"/>
                </el-select>
            </el-form-item>
            <el-form-item label="开始时间" prop="startTime">
                <el-date-picker v-model="form.startTime" placeholder="请选择开始时间" type="datetime" valueFormat="YYYY-MM-DD HH:mm" :disabled="disabled"/>
            </el-form-item>
            <el-form-item label="结束时间" prop="endTime">
                <el-date-picker v-model="form.endTime" placeholder="请选择结束时间" type="datetime" valueFormat="YYYY-MM-DD HH:mm" :disabled="disabled"/>
            </el-form-item>
            <el-form-item label="调休原因" prop="reason">
                <el-input @input="e => form.reason = validForbid(e)" type="textarea" v-model="form.reason" placeholder="请输入调休原因" maxlength="122" :disabled="disabled" clearable/>
            </el-form-item>
            <el-form-item label="备注" prop="remark">
                <el-input @input="e => form.remark = validForbid(e)" type="textarea" v-model="form.remark" placeholder="请输入备注" maxlength="122" :disabled="disabled" clearable/>
            </el-form-item>
        </el-form>
    </div>
</template>


<script>
    import PbCardHelper from "./PbCardHelper.js";
    export default PbCardHelper;
</script>

<style scoped>
    .Pb{
        width: 100%;
    }
</style>
