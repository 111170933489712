import {ref, reactive, toRefs, computed,watch,defineComponent, onMounted, getCurrentInstance,provide} from 'vue';
const PbCardHelper = defineComponent({
    name:'PbCard',
    title:'排班管理',
    modelType:'card',
    fullscreen: false,
    setup(){
        let {proxy}=getCurrentInstance();
        const utils=proxy.utils;
        let dataObj=reactive({
            formRef:null,
            disabled:false,
            refMap:new Map(),
            driverData:[],
            //卡片传给dialog的初始化参数
            compParams: {
                hsDetails: false,
                modelPath: "/pb"
            },
            //表单
            form:{},
            //表单验证规则
            rules: {
                driverId: [
                    {required: true, message: "请选择司机", trigger: "blur" }
                ],
                startTime: [
                    {required: true, message: "请选择开始时间", trigger: "blur" }
                ],
                endTime: [
                    {required: true, message: "请选择结束时间", trigger: "blur" }
                ],
                reason: [
                    {required: true, message: "请输入调休原因", trigger: "blur" }
                ]
            }
        })
        onMounted(async ()=>{
            await buildDriverData();
        })
        const buildDriverData=async ()=>{
            dataObj.driverData= await utils.$$api.getDriversByChannel({});
        }
        //---------------------------computed---------------------------
        //下拉select数据集获取（数据集构造和cascader类似）
        const comboSelect=computed(()=>{
            return (params) => {
                if(params && 'driverId'==params.comboId){
                    return dataObj.driverData;
                }
            }
        })
        //---------------------------selectOnchange--------------------------
        const selectOnChange=(newValue,selectId)=>{
            if('driverId'==selectId){}
        }
        //打开弹出框的回调事件(已请求完/add或/edit，data是返回值)
        const beforeOpen=async(data,addOrLoad,cardEngine)=>{
            console.log(data.data)
        }
        //保存之前，校验整个保存数据是否合法
        const beforeSaveHandler=()=>{
            let today=utils.$$str.formatDate(new Date());
            today=today+" 00:00:00";
            console.log(today);
            console.log(utils.$$str.formatDate(dataObj.form.startTime));
            if(dataObj.form.startTime>dataObj.form.endTime){
                utils.$$tools.warning({message:'结束时间不能小于开始时间'});
                return false;
            }else if(today>(utils.$$str.formatDate(dataObj.form.startTime)+"00:00:01")){
                utils.$$tools.warning({message:'开始时间不能小于今天'});
                return false;
            }
            return true;
        }
        const channelClaimHandler=async ()=>{
            await utils.$$lghdUtils.buildClaimDialog({proxy:proxy});
        }
        const sureHandler=async (modelInst)=>{
            let res=await utils.$$lghdUtils.claimDialogSure({modelInst:modelInst,proxy:proxy,url: dataObj.compParams.modelPath + "/custom",id:dataObj.form.id});
            return res;
        }
        return{
            ...toRefs(dataObj),comboSelect,selectOnChange,beforeOpen,beforeSaveHandler,channelClaimHandler,sureHandler
         
        }
    }
});
export default PbCardHelper;