<template>
    <div id="pageList" class="PbList">
        <ListEngine :pageList="pageList" ref="pageListRef">
            <template v-slot:queryParams>
                <el-row class="myRow">
                    <el-col :span="3" class="myColTitle">司机：</el-col>
                    <el-col :span="9">
                        <el-select :ref="el=>refMap.set('driverId',el)" v-model="pageList.queryParam.driverId" placeholder="请选择司机" clearable style="width:100%">
                            <el-option v-for="item in comboSelect({comboId:'driverId'})" :key="item.value" :label="item.label" :value="item.value"/>
                        </el-select>
                    </el-col>
                    <el-col :span="3" class="myColTitle">注册手机：</el-col>
                    <el-col :span="9">
                        <el-input @input="e => pageList.queryParam.phone = validForbid(e)" placeholder="请输入注册手机进行模糊匹配查询..." v-model="pageList.queryParam.phone" clearable/>
                    </el-col>
                </el-row>
                <el-row class="myRow">
                    <el-col :span="3" class="myColTitle">开始时间：</el-col>
                    <el-col :span="9">
                       <el-date-picker v-model="pageList.queryParam.startTime" type="daterange" :unlink-panels="true" :shortcuts="utils.$$tools.defDateRange()" style="width: 100%"/>
                    </el-col>
                    <el-col :span="3" class="myColTitle">结束时间：</el-col>
                    <el-col :span="9">
                       <el-date-picker v-model="pageList.queryParam.endTime" type="daterange" :unlink-panels="true" :shortcuts="utils.$$tools.defDateRange()" style="width: 100%"/>
                    </el-col>
                </el-row>
                <el-row class="myRow">
<!--                    <el-col :span="3" class="myColTitle">调休原因：</el-col>-->
<!--                    <el-col :span="9">-->
<!--                        <el-input @input="e => pageList.queryParam.reason = validForbid(e)" placeholder="请输入调休原因进行模糊匹配查询..." v-model="pageList.queryParam.reason" clearable/>-->
<!--                    </el-col>-->
                    <el-col :span="3" class="myColTitle">审核状态：</el-col>
                    <el-col :span="9">
                        <el-select :ref="el=>refMap.set('status',el)" v-model="pageList.queryParam.status" placeholder="请选择审核状态" clearable style="width:100%">
                            <el-option v-for="item in comboSelect({comboId:'status'})" :key="item.value" :label="item.label" :value="item.value"/>
                        </el-select>
                    </el-col>
                </el-row>
            </template>
            <template v-slot:tbCols>
                <el-table-column prop="F_DRIVER_ID" label="司机"/>
                <el-table-column prop="F_PHONE" label="注册手机"/>
                <el-table-column prop="F_START_TIME" label="开始时间"/>
                <el-table-column prop="F_END_TIME" label="结束时间"/>
                <el-table-column prop="F_STATUS" label="审核状态"/>
                <el-table-column prop="F_TIME" label="创建时间"/>
            </template>
        </ListEngine>
    </div>
</template>

<script>
    import PbCard from './PbCard'
    import {ref, reactive, toRefs, computed, defineComponent, onMounted, getCurrentInstance, watch, provide} from 'vue';
    export default defineComponent ({
        name: "PbList",
        setup(){
            const {proxy}=getCurrentInstance();
            const utils=proxy.utils;
            let dataObj=reactive({
                pageListRef:null,
                refMap:new Map(),
                driverData:[],
                pageList: {
                    queryParam: {
                       driverId:"", 
                       phone:"", 
                       startTime:"", 
                       endTime:"", 
                       reason:"", 
                       status:"" 
                    },
                    modelComp:PbCard,
                    modelMethod: "/pb/pageData"
               }
            })
            onMounted(async ()=>{
                await buildDriverData();
            })
            const buildDriverData=async ()=>{
                dataObj.driverData= await utils.$$api.getDriversByChannel({});
            }
            //---------------------------computed------------
            //下拉选择
            const comboSelect=computed(()=>{
                return (params) => {
                    if(params && 'driverId'==params.comboId){
                        return dataObj.driverData;
                    }
                    if(params && 'status'==params.comboId){
                        return [{value:0,label:'待审核'},{value:1,label:'通过'},{value:2,label:'拒绝'}]
                    }
                }
            })

            return{
                ...toRefs(dataObj),comboSelect
            }
        }
    });
</script>

<style scoped>
    .PbList{
        width: 100%;
    }
</style>